import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import BemVindo from './components/BemVindo';
import DadosFatura from './components/DadosFatura';
import MeioDePagamento from './components/MeioDePagamento';
import DadosCompra from './components/DadosCompra';
import { isAuthenticated } from './helpers/auth';

function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/'+localStorage.sistema+'/'+localStorage.hash, state: { from: props.location } }} />
        )
      }
    />
  );

  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route>
          <SnackbarProvider>
            <Route exact path='/:sistema/:hash' component={BemVindo} />
            <PrivateRoute path='/dadosFatura' component={DadosFatura} />
            <PrivateRoute path='/dadosCompra' component={DadosCompra} />
            <PrivateRoute path='/pagamento' component={MeioDePagamento} />
          </SnackbarProvider>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
