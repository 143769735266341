import axios from 'axios'
import { getToken } from '../helpers/auth';

const instance = axios.create({})

instance.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

export default instance