export const validarCnpj = valor => {
    valor = valor.replace(/\D/g, '');
    // Elimina CNPJs invalidos conhecidos
    if (valor === "00000000000000" ||
        valor === "11111111111111" ||
        valor === "22222222222222" ||
        valor === "33333333333333" ||
        valor === "44444444444444" ||
        valor === "55555555555555" ||
        valor === "66666666666666" ||
        valor === "77777777777777" ||
        valor === "88888888888888" ||
        valor === "99999999999999")
        return false;
    return true;
}

export const cnpjMask = valor => {
    return valor
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const nomeMask = valor => {
    return valor.replace(/[^a-z\s]/gi, '') // substitui qualquer caracter que nao seja valores alfanuméricos
        .replace(/\s*/, '')
        .replace(/\s{2,}/g, ' ')
}

export const isNomeValido = valor => {
    const pattern = /^(\w{2,}\s)(.*)(\w{2,})$/;
    return pattern.test(valor)
}

export const numeroCartaoMask = valor => {
    return valor
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{4})(\d)/, '$1 $2') // captura 4 grupos de numero o primeiro de 4 e o segundo de 4, apos capturar o primeiro grupo ele adiciona um espaço antes do segundo grupo de numero
        .replace(/(\d{4})(\d)/, '$1 $2')
        .replace(/(\d{4})(\d)/, '$1 $2')
        .replace(/( \d{4})\d+?$/, '$1') // captura 4 numeros seguidos de um espaço e não deixa ser digitado mais nada
}

export const numeroCartaoMaskDiferentes = (bandeira, valor) => {
    if (bandeira === 'Amex') {
        return valor
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{4})(\d)/, '$1 $2') // captura 4 grupos de numero o primeiro de 4 e o segundo de 6, apos capturar o primeiro grupo ele adiciona um espaço antes do segundo grupo de numero
            .replace(/(\d{6})(\d)/, '$1 $2')
            .replace(/( \d{5})\d+?$/, '$1') // captura 5 numeros seguidos de um espaço e não deixa ser digitado mais nada
    } else if (bandeira === 'Diners') {
        return valor
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{4})(\d)/, '$1 $2') // captura 4 grupos de numero o primeiro de 4 e o segundo de 6, apos capturar o primeiro grupo ele adiciona um espaço antes do segundo grupo de numero
            .replace(/(\d{6})(\d)/, '$1 $2')
            .replace(/( \d{4})\d+?$/, '$1') // captura 4 numeros seguidos de um espaço e não deixa ser digitado mais nada
    } else {
        return valor
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{4})(\d)/, '$1 $2') // captura 4 grupos de numero o primeiro de 4 e o segundo de 4, apos capturar o primeiro grupo ele adiciona um espaço antes do segundo grupo de numero
            .replace(/(\d{4})(\d)/, '$1 $2')
            .replace(/(\d{4})(\d)/, '$1 $2')
            .replace(/( \d{4})\d+?$/, '$1') // captura 3 numeros seguidos de um espaço e não deixa ser digitado mais nada
    }
}

export const dataValidadeMask = valor => {
    const data = valor.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, '$1/$2') // captura 2 grupos de numero o primeiro de 2 e o segundo de 4, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\/\d{4})\d+?$/, '$1') // captura 4 numeros seguidos de um espaço e não deixa ser digitado mais nada
    const mes = data.split('/')[0];
    if (mes > 12)
        return ''
    return data
}

export const cvvMask = (bandeira, valor) => {
    if (bandeira !== 'Amex' && bandeira !== 'Discover') {
        return valor
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})\d+?$/, '$1') // captura 3 numeros seguidos de um espaço e não deixa ser digitado mais nada
    }
    return valor
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{4})\d+?$/, '$1') // captura 4 numeros seguidos de um espaço e não deixa ser digitado mais nada
}

export const moneyMask = valor => {
    if (typeof valor !== "number") return valor

    return parseFloat(valor).toFixed(2)
        .toString()
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const floatMask = valor => {
    return valor.replace(',', '.')
}

export const maskReaisEmCentavos = valor => {
    return valor.replace(',', '')
        .replace('.', '')
}

export const bandeiraMask = valor => {
    var numero = valor.replace(/\D/g, '');
    var cards = {
        Master: /^5[1-5][0-9]{14}/,
        Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        Amex: /^3[47][0-9]{13}/,
        Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        Elo: /^((((636368)|(431274)|(438935)|(504175)|(451416)|(457393)|(45763[1-2])|(636297)|(627780)|(6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))|(6500(3[5-9]|4[0-9]|5[0-1]))|(6504(0[5-9]|[1-3][0-9]))|(650(4[8-9][0-9]|5[0-2][0-9]|53[0-8]))|(6505(4[1-9]|[5-8][0-9]|9[0-8]))|(6507(0[0-9]|1[0-8]))|(65072[0-7])|(6516(5[2-9]|[6-7][0-9]))|(6509(0[1-9]|1[0-9]|20))|(65003[1-3]))\d{0,10})|((506(699|7[0-6][0-9]|77[0-8]))|(509([0-9][0-9][0-9]))|(4576)|(4011))\d{0,12})/,
        Visa: /^4[0-9]{12}(?:[0-9]{3})/,
        Jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        Aura: /^(50\d{2})[0-9]{12}$/,
        Cabal: /^((627170)|(589657)|(603522)|(604((20[1-9])|(2[1-9][0-9])|(3[0-9]{2})|(400))))/,
        Naranja: /^589562(\d{10})/,
        Nevada: /^548955(\d{10})/,
        Credz: /^6367(\d{12})/,
        Banese: /^585988(\d{10})/
    };

    for (var flag in cards) {
        if (cards[flag].test(numero)) {
            return flag;
        }
    }
    return 'Outros';
}