import http from './http'

export const consultarFatura = (params) =>
    http.post(`${process.env.REACT_APP_API_FATURA_URL}/fatura/consultar`, {
        hash: params.hash,
        cnpj: params.cnpj
    })

export const pagarFatura = (params) =>
    http.post(`${process.env.REACT_APP_API_FATURA_URL}/fatura/pagar`, {
        hash: params.hash,
        sistema: params.sistema,
        cliente: params.cliente,
        pagamento: params.pagamento
    }) 