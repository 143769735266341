import http from './http'

export const consultarOportunidade = (params) =>
    http.get(`${process.env.REACT_APP_API_OPORTUNIDADE_URL}/oportunidade/consultar/${params.hash}/${params.cnpj.replace(/\D/g, '')}`)

export const pagarOportunidade = (params) =>
    http.post(`${process.env.REACT_APP_API_OPORTUNIDADE_URL}/oportunidade/pagamento`, {
        hash: params.hash,
        sistema: params.sistema,
        cliente: params.cliente,
        pagamento: params.pagamento
    }) 