import React from 'react';
import logo from '../assets/images/logo.svg';
import '../assets/css/reset.css';
import '../assets/css/style.css';
import '../assets/css/bootstrap.css';
import { consultarFatura } from '../api/fatura';
import { cnpjMask, validarCnpj } from '../helpers/formatters'
import { withSnackbar } from 'notistack';
import { consultarOportunidade } from '../api/oportunidade';
import { CircularProgress } from '@material-ui/core';
import { login } from '../helpers/auth';

class BemVindo extends React.Component {
    constructor() {
        super();

        this.state = {
            documentoId: '',
            botaoDesabilitado: true,
            showAguarde: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = event => {
        this.setState({ botaoDesabilitado: true });
        this.setState({ showAguarde: false });
    }

    handlechangeDocumento = event => {
        this.setState({ botaoDesabilitado: true });
        console.log(event.target.value)
        const documentoId = cnpjMask(event.target.value);
        if (!validarCnpj(documentoId)) {
            document.getElementById('botaoAvancar').disabled = true;
            this.props.enqueueSnackbar("CNPJ Informado é inválido.", { variant: 'error' });
        } else {
            this.request = {
                hash: this.props.match.params.hash,
                cnpj: documentoId
            };

            if (documentoId.length === 18) {
                this.setState({ botaoDesabilitado: false });
            }
        }
        this.setState({ documentoId });
    }

    avancar = () => {
        const sistema = this.props.match.params.sistema;
        this.setState({ showAguarde: true });
        if (sistema === 'fatura') {
            consultarFatura(this.request)
                .then((resultado) => {
                    if (resultado.data.status.codigo === -1) {
                        this.setState({ showAguarde: false });
                        this.props.enqueueSnackbar(resultado.data.status.statusItem.descricao, { variant: 'error' });
                        document.getElementById('botaoAvancar').disabled = true;
                    } else {
                        this.onSetResultado(resultado);
                        this.setState({ showAguarde: false });
                        if (resultado.data.customer.fatura !== undefined && resultado.data.customer.fatura.linkBoleto !== undefined) {
                            this.props.enqueueSnackbar('Já foi gerado um boleto anteriormente para esse Cliente!', { variant: 'success' });
                            window.open(resultado.data.customer.fatura.linkBoleto, "Boleto Fatura QUOD", 'toolbar=0,status=0,width=1300,height=700');
                        } else {
                            login(resultado.data.customer.codigo);
                            this.props.history.push('/dadosFatura');
                        }
                    }
                })
                .catch(() => {
                    this.setState({ showAguarde: false });
                    this.props.enqueueSnackbar('Erro inesperado', { variant: 'error' })
                });
        } else if (sistema === 'televendas') {
            consultarOportunidade(this.request)
                .then((resultado) => {
                    if (resultado.status === 204) {
                        this.setState({ showAguarde: false });
                        this.props.enqueueSnackbar("Não foi encontrada nenhuma Oportunidade para este CNPJ.", { variant: 'error' });
                        document.getElementById('botaoAvancar').disabled = true;
                    } else if (resultado.data.status.codigo === -1) {
                        this.setState({ showAguarde: false });
                        this.props.enqueueSnackbar(resultado.data.status.statusItem.descricao, { variant: 'error' });
                        document.getElementById('botaoAvancar').disabled = true;
                    } else {
                        this.onSetResultado(resultado);
                        this.setState({ showAguarde: false });
                        if (resultado.data.cliente.oportunidade !== undefined && resultado.data.cliente.oportunidade.linkBoleto !== undefined) {
                            this.props.enqueueSnackbar('Já foi gerado um boleto anteriormente para esse Cliente!', { variant: 'success' });
                            window.open(resultado.data.cliente.oportunidade.linkBoleto, "Boleto Oportunidade QUOD", 'toolbar=0,status=0,width=1300,height=700');
                        } else {
                            login(resultado.data.cliente.codigo);
                            this.props.history.push('/dadosCompra');
                        }
                    }
                })
                .catch((error) => {
                    this.setState({ showAguarde: false });
                    if (error.response.status !== 500 && error.response.status !== 504) {
                        this.props.enqueueSnackbar(error.response.data.status.statusItem.descricao, { variant: 'error' });
                        document.getElementById('botaoAvancar').disabled = true;
                    } else {
                        this.props.enqueueSnackbar('Ocorreu um erro inesperado, tente novamente mais tarde!', { variant: 'error' });
                    }
                });
        }
    }

    onSetResultado = (result) => {
        localStorage.setItem('dados', JSON.stringify(result.data));
        localStorage.setItem('hash', this.props.match.params.hash);
        localStorage.setItem('cnpj', this.state.documentoId);
    };

    render() {
        let aguarde = '';
        if (this.state.showAguarde)
            aguarde = <div className="divAguarde"><CircularProgress className="aguarde" /></div>
        return (
            <div className="container">
                <div className="container-logo d-flex align-items-center justify-content-center">
                    <img className="logo" src={logo} alt='' />
                </div>
                {aguarde}
                <div className="container-login text-center d-flex align-items-center justify-content-center">
                    <div className="container-content">
                        <h1>Bem-vindo à Quod!</h1>
                        <p className="pb-4 bemVindo">Preencha o campo abaixo para seguir com o pagamento.</p>
                        <input name="ocumentoId" maxLength="18" value={this.state.documentoId} onChange={this.handlechangeDocumento} className="input-login mb-3 pl-3" type="text" placeholder="Digite seu CPF ou CNPJ" />
                        <br />
                        <button id="botaoAvancar" className="button" onClick={this.avancar} disabled={this.state.botaoDesabilitado}>Avançar</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(BemVindo);
