import React from 'react';
import logo from '../assets/images/logo.svg';
import '../assets/css/reset.css';
import '../assets/css/style.css';
import '../assets/css/bootstrap.css';
import { login } from '../helpers/auth';

class DadosFatura extends React.Component {

    avancar = () => {
        localStorage.setItem('dados', localStorage.dados);
        localStorage.setItem('hash', localStorage.hash);
        localStorage.setItem('sistema', 'fatura');
        this.props.history.push('/pagamento');
    }

    render() {
        const dados = JSON.parse(localStorage.dados);
        login(dados.customer.codigo);

        const produtos = dados.customer.fatura.produtos.map(function (prod, index) {
            return (
                <tr key={index}>
                    <td>{prod.nome}</td>
                </tr>
            );
        });

        return (
            <div className="container">
                <div className="container-logo d-flex align-items-center justify-content-center">
                    <img className="logo" src={logo} alt='' />
                </div>
                <div className="container-compras text-center d-flex align-items-center justify-content-center">
                    <div className="container-content">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-start">
                                <h1>Dados da Fatura</h1>
                            </div>

                            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 d-flex justify-content-start mt-3 mb-3">
                                <div className="container-input-nome">
                                    <p className="d-flex justify-content-start dados">Razão Social</p>
                                    <input disabled type="text" value={dados.customer.nome} className="input-login pl-3 d-flex justify-content-start" />
                                </div>
                            </div>

                            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-xs-start mt-3 mb-3">
                                <div className="container-input-cnpj">
                                    <p className="d-flex justify-content-start dados">CNPJ</p>
                                    <input disabled type="text" value={dados.customer.cnpj} className="input-login pl-3 d-flex justify-content-end" />
                                </div>
                            </div>

                            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-xs-start mt-3 mb-3">
                                <div className="container-input-nome">
                                    <p className="d-flex justify-content-start dados">Número Contrato</p>
                                    <input disabled type="text" value={dados.customer.fatura.numeroContrato} className="input-login pl-3 d-flex justify-content-start" />
                                </div>
                            </div>

                            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 d-flex justify-content-start mt-3 mb-3">
                                <div className="container-input-cnpj">
                                    <p className="d-flex justify-content-start dados">Número Fatura</p>
                                    <input disabled type="text" value={dados.customer.fatura.codigoTransacao} className="input-login pl-3 d-flex justify-content-end" />
                                </div>
                            </div>

                            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-xs-start mt-3 mb-3">
                                <div className="container-input-nome">
                                    <p className="d-flex justify-content-start dados">Vencimento</p>
                                    <input disabled type="text" value={dados.customer.fatura.dataVencimento} className="input-login pl-3 d-flex justify-content-start" />
                                </div>
                            </div>

                            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-xs-start mt-3 mb-3">
                                <div className="container-input-cnpj">
                                    <p className="d-flex justify-content-start dados">Valor (R$)</p>
                                    <input disabled type="text" value={dados.customer.fatura.valor} className="input-login pl-3 d-flex justify-content-end" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-3 mb-3">
                                <table className="border-table table text-left">
                                    <thead className="titulos-tabela">
                                        <tr>
                                            <th scope="col">Produtos</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {produtos}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <button className="button mt-3" onClick={this.avancar} >Avançar</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DadosFatura;