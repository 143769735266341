import React from 'react';
import logo from '../assets/images/logo.svg';
import arrow from '../assets/images/arrow.svg';
import barcode from '../assets/images/barcode.svg';
import commerce from '../assets/images/commerce.svg';
import '../assets/css/reset.css';
import '../assets/css/style.css';
import '../assets/css/bootstrap.css';
import { pagarFatura } from '../api/fatura';
import { numeroCartaoMask, numeroCartaoMaskDiferentes, bandeiraMask, dataValidadeMask, cvvMask, floatMask, nomeMask, maskReaisEmCentavos, isNomeValido } from '../helpers/formatters'
import { withSnackbar } from 'notistack';
import { pagarOportunidade } from '../api/oportunidade';
import { CircularProgress } from '@material-ui/core';
import { logout } from '../helpers/auth';

class MeioDePagamento extends React.Component {

    constructor() {
        super();

        this.state = {
            nomeCartao: '',
            numeroCartao: '',
            bandeira: '',
            dataValidade: '',
            cvv: '',
            codigoPagamento: 3,
            showAguarde: false,
            classErrorNome: false,
            classErrorCartao: false,
            classErrorCvv: false,
            classErrorData: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = event => {
        this.setState({ showAguarde: false });
        if (event.target.name === 'numeroCartao') {
            this.setState({ numeroCartao: numeroCartaoMask(event.target.value) });
            this.setState({ bandeira: bandeiraMask(event.target.value) });
        } else if (event.target.name === 'dataValidade') {
            this.setState({ [event.target.name]: dataValidadeMask(event.target.value) });
        } else if (event.target.name === 'cvv') {
            this.setState({ [event.target.name]: cvvMask(this.state.bandeira, event.target.value) });
        } else {
            this.setState({ [event.target.name]: nomeMask(event.target.value) });
        }
    }

    handleBlur = event => {
        if (event.target.name === 'numeroCartao') {
            this.setState({ classErrorCartao: false });
            if (event.target.value.length < 13) {
                this.setState({ classErrorCartao: true });
            }
        } else if (event.target.name === 'dataValidade') {
            this.setState({ classErrorData: false });
            const dataExp = new Date(event.target.value.split('/')[0] + '/31/' + event.target.value.split('/')[1]);
            if (event.target.value.length < 7 || dataExp < new Date()) {
                this.setState({ classErrorData: true });
            }
        } else if (event.target.name === 'cvv') {
            this.setState({ classErrorCvv: false });
            if (!this.validaCvvBandeira(this.state.bandeira, event.target.value)) {
                this.setState({ classErrorCvv: true });
            }
        } else {
            this.setState({ classErrorNome: false });
            if (!isNomeValido(event.target.value)) {
                this.setState({ classErrorNome: true });
            }
        }
    }

    criaRequestFatura = () => {
        const dados = JSON.parse(localStorage.dados);
        const request = {
            sistema: 'quod-pagamento-web',
            hash: localStorage.hash,
            cliente: {
                codigo: dados.customer.codigo,
                nome: dados.customer.nome,
                cnpj: localStorage.cnpj,
                fatura: dados.customer.fatura
            },
            pagamento: {
                codigo: this.state.codigoPagamento,
                cartaoCredito: {
                    nome: this.state.nomeCartao,
                    bandeira: this.state.bandeira,
                    numero: this.state.numeroCartao.replace(/\D/g, ''),
                    validade: this.state.dataValidade,
                    cvv: this.state.cvv
                },
                valorPagamento: parseFloat(floatMask(dados.customer.fatura.valor)),
                numeroParcelas: '1',
                urlurlBoletoBillet: dados.customer.fatura.linkBoleto
            }
        }
        return request;
    }

    criaRequestOportunidade = () => {
        const dados = JSON.parse(localStorage.dados);
        const valor = localStorage.valor;
        const request = {
            sistema: 'quod-pagamento-web',
            hash: localStorage.hash,
            cliente: {
                codigo: dados.cliente.codigo,
                nome: dados.cliente.nome,
                cnpj: localStorage.cnpj.replace(/\D/g, ''),
                oportunidade: dados.cliente.oportunidade
            },
            pagamento: {
                codigo: this.state.codigoPagamento,
                cartaoCredito: {
                    nome: this.state.nomeCartao,
                    bandeira: this.state.bandeira,
                    numero: this.state.numeroCartao.replace(/\D/g, ''),
                    validade: this.state.dataValidade,
                    cvv: this.state.cvv
                },
                valorPagamento: parseFloat(maskReaisEmCentavos(valor)),
                numeroParcelas: '1',
            }
        }
        return request;
    }

    validaDados = () => {
        const dataExp = new Date(this.state.dataValidade.split('/')[0] + '/31/' + this.state.dataValidade.split('/')[1]);
        if (isNomeValido(this.state.nomeCartao) && this.state.numeroCartao.length > 13
            && this.state.dataValidade.length > 6
            && this.validaCvvBandeira(this.state.bandeira, this.state.cvv)
            && dataExp > new Date()) {
            return false;
        }
        return true;
    }

    validaCvvBandeira = (bandeira, cvv) => {
        if ((cvv.length !== 3 && bandeira !== 'Amex' && bandeira !== 'Discover')
            || (cvv.length !== 4 && (bandeira === 'Amex' || bandeira === 'Discover'))) {
            return false;
        }
        return true;
    }

    pagar = () => {
        const sistema = localStorage.sistema;
        this.setState({ showAguarde: true });
        if (sistema === 'fatura') {
            const request = this.criaRequestFatura();
            pagarFatura(request)
                .then((resultado) => {
                    this.setState({ showAguarde: false });
                    /* codigo 350 = A fatura não está mais acessível por esse canal! */
                    if (resultado.data.status.codigo === -1 || resultado.data.status.statusItem.codigo === 350) {
                        this.props.enqueueSnackbar(resultado.data.status.statusItem.descricao, { variant: 'error' });
                    } else {
                        this.props.enqueueSnackbar(resultado.data.status.statusItem.descricao, { variant: 'success' });
                        if (resultado.data.pagamento !== undefined && resultado.data.pagamento.urlBoleto !== undefined) {
                            window.open(resultado.data.pagamento.urlBoleto, "Boleto Fatura QUOD", 'toolbar=0,status=0,width=1300,height=700');
                        }
                    }
                    document.getElementById('botaoPagar').disabled = true;
                    document.getElementById('botaoGerarBoleto').disabled = true;
                    logout();
                })
                .catch((error) => {
                    this.setState({ showAguarde: false });
                    if (error.response.status !== 500 && error.response.status !== 504) {
                        this.props.enqueueSnackbar(error.response.data.status.statusItem.descricao, { variant: 'error' })
                    } else {
                        this.props.enqueueSnackbar('Ocorreu um erro inesperado, tente novamente mais tarde!', { variant: 'error' });
                    }
                });
        } else if (sistema === 'televendas') {
            const request = this.criaRequestOportunidade();
            pagarOportunidade(request)
                .then((resultado) => {
                    this.setState({ showAguarde: false });
                    if (resultado.data.status.codigo === -1) {
                        this.props.enqueueSnackbar(resultado.data.status.statusItem.descricao, { variant: 'error' });
                    } else {
                        this.props.enqueueSnackbar(resultado.data.status.statusItem.descricao, { variant: 'success' });
                        if (this.state.codigoPagamento === 11) {
                            window.open(resultado.data.pagamento.urlBoleto, "Boleto Televendas QUOD", 'toolbar=0,status=0,width=1300,height=700');
                        }
                    }
                    document.getElementById('botaoPagar').disabled = true;
                    document.getElementById('botaoGerarBoleto').disabled = true;
                    logout();
                })
                .catch((error) => {
                    this.setState({ showAguarde: false });
                    if (error.response.status !== 500 && error.response.status !== 504) {
                        this.props.enqueueSnackbar(error.response.data.status.statusItem.descricao, { variant: 'error' });
                    } else {
                        this.props.enqueueSnackbar('Ocorreu um erro inesperado, tente novamente mais tarde!', { variant: 'error' });
                    }
                });
        }
    }

    selecionaTipo = (codigo) => {
        this.setState({ codigoPagamento: codigo });
    }

    pegaClasseCartao = () => {
        let retorno = 'row container-formas-pagamento-cartao d-flex align-items-center pt-3 pb-3';
        if (this.state.codigoPagamento === 3) {
            return retorno + ' container-formas-pagamento';
        }
        return retorno;
    }

    pegaClasseBoleto = () => {
        let retorno = 'row container-formas-pagamento-boleto d-flex align-items-center pt-3 pb-3';
        if (this.state.codigoPagamento === 11) {
            return retorno + ' container-formas-pagamento';
        }
        return retorno;
    }

    criaTiposPagamento = () => {
        const dados = JSON.parse(localStorage.dados);
        let div = [];
        for (let i = 0; i < dados.tiposPagamento.length; i++) {
            const tipos = dados.tiposPagamento[i];
            if (tipos.codigo !== 4) {
                div.push(
                    <div onClick={e => this.selecionaTipo(tipos.codigo)} key={i} className={tipos.codigo === 3 ? this.pegaClasseCartao() : this.pegaClasseBoleto()}>
                        <div className=" col-2">
                            <img className="icon-credit" src={tipos.codigo === 3 ? commerce : barcode} alt='' />
                        </div>
                        <div className=" col-7 d-flex justify-content-start">
                            <label>{tipos.nome}</label>
                        </div>
                        <div className="col-3 d-flex justify-content-end">
                            <img className="icon-arrow mr-2" src={arrow} alt='' />
                        </div>
                    </div>
                )
            }
        }
        return div;
    }

    buscaImagemBandeira = () => {
        if (this.state.bandeira)
            return (
                <img className="brands" src={require("../assets/images/bandeiras/" + this.state.bandeira + ".png")} alt='' />
            );
    }

    clienteFatura = () => {
        const dados = JSON.parse(localStorage.dados);
        return (
            <div className="container-content-2 d-flex mt-4">
                <div className="container-input-nome ml-4 mr-4">
                    <p className="d-flex justify-content-start mb-2 pagamento">Número Contrato</p>
                    <input disabled type="text" value={dados.customer.fatura.numeroContrato} className="input-login-pagamento pl-3 d-flex justify-content-start" />
                </div>
                <div className="container-input-nome ml-3 mr-3">
                    <p className="d-flex justify-content-start mb-2 pagamento">Número Fatura</p>
                    <input disabled type="text" value={dados.customer.fatura.codigoTransacao} className="input-login-pagamento pl-3 d-flex justify-content-start" />
                </div>
            </div>
        )
    }

    clienteOportunidade = () => {
        const dados = JSON.parse(localStorage.dados);
        return (
            <div className="container-content-2 d-flex mt-4">
                <div className="container-input-nome ml-4 mr-4">
                    <p className="d-flex justify-content-start mb-2 pagamento">Pedido</p>
                    <input disabled type="text" value={dados.cliente.oportunidade.codigo} className="input-login-pagamento pl-3 d-flex justify-content-start" />
                </div>
                <div className="container-input-nome ml-3 mr-3">
                    <p className="d-flex justify-content-start mb-2 pagamento">Código Transação</p>
                    <input disabled type="text" value={dados.cliente.oportunidade.codigoTransacao} className="input-login-pagamento pl-3 d-flex justify-content-start" />
                </div>
            </div>
        )
    }

    render() {
        let aguarde = '';
        if (this.state.showAguarde)
            aguarde = <div className="divAguarde"><CircularProgress className="aguarde" /></div>

        const dados = JSON.parse(localStorage.dados);
        let cliente = dados.customer;
        let clienteSistema = '';
        if (!cliente) {
            cliente = dados.cliente
            clienteSistema = this.clienteOportunidade();
        } else {
            clienteSistema = this.clienteFatura();
        }

        const botaoDesabilitado = this.validaDados();

        const className = "input-login-pagamento pl-3 d-flex justify-content-start";
        const classNameError = "input-login-pagamento pl-3 d-flex justify-content-start error";

        return (
            <div className="container">
                <div className="container-logo d-flex align-items-center justify-content-center">
                    <img className="logo" src={logo} alt='' />
                </div>
                <div className="container-compras text-center d-flex align-items-center justify-content-center">
                    <div className="container-content">
                        <div className="row">
                            <div className="col-12 pl-0 mb-3 d-flex justify-content-start">
                                <h1>Meios de Pagamento</h1>
                            </div>
                            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                                {this.criaTiposPagamento()}
                            </div>
                            {aguarde}
                            <div className="col-lg-8 col-xl-8 col-md-8 col-sm-12 col-xs-12 d-flex justify-content-xl-start justify-content-lg-center justify-content-md-center justify-content-sm-start justify-content-xs-start mt-3 mb-3">
                                <div className={this.state.codigoPagamento === 3 ? "container-input-form" : "hidden"}>
                                    <div className="container-input-nome ml-3 mr-3 mt-4">
                                        <p className="d-flex justify-content-start mb-2 pagamento">Nome impresso no cartão</p>
                                        <input type="text" name="nomeCartao" maxLength="30" value={this.state.nomeCartao} className={this.state.classErrorNome ? classNameError : className} placeholder="Ex: João Pereira Rodrigues da Silva" onChange={this.handleChange} onBlur={this.handleBlur} />
                                    </div>
                                    <div className="container-input-nome ml-3 mr-3 mt-4">
                                        <p className="d-flex justify-content-start mb-2 pagamento">Número do cartão</p>
                                        <input type="text" name="numeroCartao" value={numeroCartaoMaskDiferentes(this.state.bandeira, this.state.numeroCartao)} className={this.state.classErrorCartao ? classNameError : className} placeholder="Ex: 1234 1234 1234 1234" onChange={this.handleChange} onBlur={this.handleBlur} />
                                        {this.buscaImagemBandeira()}
                                    </div>
                                    <div className="container-content-2 d-flex mt-4">
                                        <div className="container-input-nome ml-4 mr-4">
                                            <p className="d-flex justify-content-start mb-2 pagamento">Validade</p>
                                            <input type="text" name="dataValidade" value={this.state.dataValidade} className={this.state.classErrorData ? classNameError : className} placeholder="Ex: 12/2020" onChange={this.handleChange} onBlur={this.handleBlur} />
                                        </div>
                                        <div className="container-input-nome ml-3 mr-3">
                                            <p className="d-flex justify-content-start mb-2 pagamento">Código de Segurança</p>
                                            <input type="text" name="cvv" value={this.state.cvv} className={this.state.classErrorCvv ? classNameError : className} placeholder="Ex: 123" onChange={this.handleChange} onBlur={this.handleBlur} />
                                        </div>
                                    </div>
                                    <button id="botaoPagar" className="buttonPagamento mt-5 mb-4 ml-3 mr-3" disabled={botaoDesabilitado} onClick={this.pagar}>Realizar pagamento</button>
                                </div>
                                <div className={this.state.codigoPagamento === 11 ? "container-input-form" : "hidden"}>
                                    <div className="container-input-nome ml-3 mr-3 mt-4">
                                        <p className="d-flex justify-content-start mb-2 pagamento">Razão Social</p>
                                        <input disabled type="text" value={cliente.nome} className="input-login-pagamento pl-3 d-flex justify-content-start" />
                                    </div>
                                    <div className="container-input-nome ml-3 mr-3 mt-4">
                                        <p className="d-flex justify-content-start mb-2 pagamento">CNPJ</p>
                                        <input disabled type="text" value={cliente.cnpj} className="input-login-pagamento pl-3 d-flex justify-content-start" />
                                    </div>
                                    {clienteSistema}
                                    <button id="botaoGerarBoleto" className="buttonPagamento mt-5 mb-4 ml-3 mr-3" onClick={this.pagar}>Gerar boleto</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(MeioDePagamento);